<template>
  <div class="hk">
    <!-- <div class="header_pic">
      <img src="~@/assets/images/hk/header_img.png" alt="" />
    </div> -->
    <div class="idCard">
      <div class="idCard_left">
        <div>
          <p>卡号：</p>
          <p>{{info.old_iccid}}</p>
        </div>
        <div>
          <p>余额：</p>
          <p>{{info.c_balance}}</p>
        </div>
        <div>
          <p>到期时间：</p>
          <p>{{info.c_extime}}</p>
        </div>
      </div>
      <div class="idCard_right">
        <img src="~@/assets/images/hk/header_img.png" alt="">
      </div>
    </div>
    <div class="main_box">
      <div class="main_tip" style="margin-top:0">
          *由于运营商限速问题，我们为您提供了一张不限速的新流量卡，老卡将在7天内停用，请尽快确认地址以便为您邮寄新卡，原套餐已转移到新卡。
      </div>
      <div>
        <div class="popBox">     
          <div class="inp">
            <van-cell-group>
              <van-field
                v-model="form.name"
                label="姓名"
                placeholder="请输入真实姓名"
              />
            </van-cell-group>
          </div>
          <div class="inp">
            <van-cell-group>
              <van-field
                v-model="form.phone"
                label="电话"
                type="tel"
                maxlength="11"
                placeholder="请输入联系电话"
              />
            </van-cell-group>
          </div>
          <div class="inp">
            <van-cell-group>
              <van-field
                v-if="form.showCode"
                v-model="form.cardId"
                label="验证码"
                type="tel"
                maxlength="6"
                placeholder="请填写手机验证码"
              >
                <template #button>
                  <!-- <van-button size="small" type="primary">发送验证码</van-button> -->
                  <div
                    style="color: #fff; padding:0 10px; background:#1E6FFF;border-radius: 60px;"
                    @click="getCode()"
                    v-if="!disabled"
                  >
                    {{ codeText }}
                  </div>
                  <div style="color: #f1462e; padding: 0 20px" v-if="disabled">
                    {{ codeText }}
                  </div>
                </template>
              </van-field>
            </van-cell-group>
          </div>
          <div class="area">
            <div class="area-a">地址</div>
            <div style="width: 100%; height: 44px; line-height: 44px">
              <div @click="picker = true">
                {{ form.province + " " + form.city + " " + form.area }}
              </div>
            </div>
          </div>

          <div class="inp">
            <van-cell-group>
              <van-field
                v-model="form.addressInfo"
                placeholder="请输入详细地址"
              />
            </van-cell-group>
          </div>

          <div class="footbtn">
            <van-button
              :disabled="disabled2"
              type="primary"
              round
              size="large"
              color="#1E6FFF"
              @click="onbuyCard()"
              >确认地址</van-button
            >
          </div>
        </div>
      </div>

      <van-popup v-model="picker" position="bottom" :style="{ height: '50%' }">
        <van-area
          @confirm="confirm"
          @cancel="cancel()"
          title="选择地址"
          :area-list="areaList"
          :value="areainfo"
          
        />
      </van-popup>
    </div>
    <div class="card1">
        <div>注意事项：</div>
        <p>1、如有任何疑问，请及时联系我们的客服人员。</p>
        <p>2、感谢您的配合和理解，我们将竭诚为您提供优质的服务。</p>
        <p>3、联系客服，进入通信网服中心，回复人工或人工客服或99，即可接入人工。</p>
    </div>
    <!-- <div class="card3">
      <div class="header">
        <div class="name">商品详细</div>
        <div class="red"></div>
      </div>
      <div>
        <img
          src="https://staticimg.yuna8.com/tmp/uploads/20231115/170004211112.jpeg"
          alt=""
          style="width: 100%"
        />
      </div>
    </div> -->
  </div>
</template>

<script>
var url = window.location.href; //获取当前url
var cs = url.split("?")[1]; //获取?之后的参数字符串
var cs_arr = cs.split("&"); //参数字符串分割为数组
var cs = {};
for (var i = 0; i < cs_arr.length; i++) {
  cs[cs_arr[i].split("=")[0]] = cs_arr[i].split("=")[1];
}
console.log(cs, "cs");
import { areaList } from "@vant/area-data";
import {
  sendVerifyCode,
  getAddressByIccid,
  hkOrder,
} from "@/untils/distubritionIndex.js";
export default {
  data() {
    return {
      picker: false,
      areaList,
      form: {
        name: "",
        phone: "",
        cardId: "",
        addressInfo: "",
        province: "",
        city: "",
        area: "",
        showCode: false,
      },
      codeText: "获取验证码",
      disabled: false,
      disabled2: false,
      areainfo: "",
      result: {},
      old_iccid: "",
      info:{},
    };
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    //获取信息
    getInfo() {
      let data = {
        news_id: cs.i,
      };
      getAddressByIccid({ data }).then((res) => {
        console.log(res, "res");
        // 如果code=2 说明没有查到收货地址  code=0 是信息异常  1 正常
        if (res.data.code == 1) {
          this.info = res.data.data;
          this.info.c_extime = res.data.data.c_extime.slice(0, -8)
          this.form.name = res.data.data.name;
          this.form.phone = res.data.data.phone;
          this.form.province = res.data.data.sheng;
          this.form.city = res.data.data.shi;
          this.form.area = res.data.data.qu;
          this.form.addressInfo = res.data.data.address;
          this.form.showCode = false;
          this.old_iccid = res.data.data.old_iccid;
          this.result.Province = res.data.data.sheng;
          this.result.City = res.data.data.shi;
          this.result.District = res.data.data.qu;
          this.getAddress();
          // this.form.address= res.data.data.sheng + res.data.data.shi + res.data.data.qu
        } else if (res.data.code == 2) {
          this.info = res.data.data;
          this.info.c_extime = res.data.data.c_extime.slice(0, -8)
          this.old_iccid = res.data.data.old_iccid;
          this.form.province = "选择省";
          this.form.city = "/ 市";
          this.form.area = "/ 区";
          this.form.showCode = true;
          this.$toast(res.data.msg);
        } else {
          this.disabled2 = true;
          this.form.showCode = false;
          this.form.province = "选择省";
          this.form.city = "/ 市";
          this.form.area = "/ 区";
          this.$toast(res.data.msg);
        }
      });
    },
    // 获取地址
    getAddress() {
      console.log(this.areaList.province_list, "省");
      this.provinceList = this.areaList.province_list;
      this.cityList = this.areaList.city_list;
      this.infoList = this.areaList.county_list;
      const obj = this.provinceList;
      const obj2 = this.cityList;
      const obj3 = this.infoList;
      for (let i in obj) {
        if (obj[i].includes(this.result.Province)) {
          this.form.province = obj[i];
        }
      }
      for (let i in obj2) {
        if (obj2[i].includes(this.result.City)) {
          this.areainfo = i;
          this.form.city = obj2[i];
        }
      }

      if (this.result.District != "") {
        for (let i in obj3) {
          if (obj3[i].includes(this.result.District)) {
            this.areainfo = i;
            this.form.area = obj3[i];
          }
        }
      } else {
        let areaArr = [];
        for (let i in obj3) {
          if (i.includes(this.areainfo.slice(0, 4))) {
            // console.log(i);
            // console.log(obj3[i]);
            areaArr.push({ val: i, name: obj3[i] });
          }
        }

        this.areainfo = areaArr[0].val;
        this.form.area = areaArr[0].name;
        console.log( this.areainfo,'明白了');
      }
    },
    //确认地址
    onbuyCard() {
      this.disabled2 =true
      let data = {
        name: this.form.name,
        phone: this.form.phone,
        province: this.form.province,
        city: this.form.city,
        area: this.form.area,
        code: this.form.cardId ? this.form.cardId : "",
        address: this.form.addressInfo,
        old_iccid: this.old_iccid,
      };
      hkOrder({ data }).then((res) => {
        this.disabled2 = false
        this.$toast(res.data.msg);
      });
    },
    // 获取验证码
    getCode() {
      if (this.form.phone.trim().length != 11) {
        return this.$toast("请输入完整的手机号");
      }
      this.disabled = true;
      this.codeText = 60;
      let data = {
        phone: this.form.phone,
      };

      sendVerifyCode({ data }).then((res) => {
        console.log(res);
        if (res.data.code == 1) {
          this.$toast(res.data.msg);
        }
      });
      let timer = setInterval(() => {
        this.codeText--;
        if (this.codeText == 0) {
          this.codeText = "获取验证码";
          clearInterval(timer);
          this.disabled = false;
        }
      }, 1000);
    },
    // 确定
    confirm(value) {
      // console.log(value);
      this.form.province = value[0].name;
      this.form.city = value[1].name;
      this.form.area = value[2].name;
      this.form.address =
        this.form.province + " " + this.form.city + " " + this.form.area;
      this.picker = false;
    },
    cancel() {
      this.picker = false;
    },
  },
};
</script>

<style lang="less" scoped>
.hk {
  min-height: 100vh;
  background: #F8F9FA;
  overflow: hidden;
  .idCard{
    display: flex;
    justify-content: space-between;
    margin: 15px;
    font-size: 14px;
    background: linear-gradient(141deg, #1088FE 34%, #19A2F0 103%);
    border-radius: 10px;
    padding: 10px 20px;
    color: #fff;
    font-size: 16px;
    .idCard_left{
      div{
        margin: 15px 0;
        display: flex;
        // p:nth-child(1){
        //   text-align: right;
        // }
        // p{
        //   width: 80px;
        //   white-space: nowrap;
        // }
      }
    }
    .idCard_right{
      img{
        width: 60px;
        height: 60px;
      }
    }
  }
  .header_pic {
    img {
      width: 100%;
    }
  }
  .main_box {
    background: #FFFFFF;
    box-shadow: 0px 2px 10px 0px rgba(5, 54, 102, 0.1);
    border-radius: 10px;
    margin: 15px;
    overflow: hidden;
    .main_tip{
      font-size: 14px;
      color: #ff5252;
      padding: 15px 0;
      margin:  0 15px;
      line-height: 18px;
      text-indent: 2em;
    }
    .area {
      margin-bottom: 10px;
      display: flex;
      border-radius: 10px;
      background-color: #f7f5f5;
      height: 44px;
      // line-height: 44px;
      box-sizing: border-box;
      padding: 10px 16px;
      font-size: 14px;
      align-items: center;

      .area-a {
        -webkit-box-flex: 0;
        -webkit-flex: none;
        flex: none;
        box-sizing: border-box;
        width: 51px;
        margin-right: 0.32rem;
        color: #646566;
        text-align: left;
        word-wrap: break-word;
      }
    }
    .popBox {
      width: 100%;
      padding: 10px;
      box-sizing: border-box;
      // background-color: #999999;
      height: 100%;
      
      .inp {
        margin-bottom: 10px;
      }

      .payType {
        box-sizing: border-box;
        position: relative;
        width: 100%;
        background-color: #f7f5f5;
        border-radius: 10px;
        padding: 0 10px;
        box-sizing: border-box;

        .item {
          height: 54px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .left {
            img {
              width: 32px;
              height: 32px;
              margin-right: 10px;
            }
          }

          .right {
            img {
              width: 16px;
              height: 16px;
            }
          }
        }

        // .item:nth-child(1) {
        //     // border-bottom: 1px solid #e6e6e6;
        // }
      }

      /deep/ .van-cell-group {
        background-color: #f7f5f5;
        border-radius: 10px;

        .van-cell__title {
          width: 50px;
        }

        .van-cell {
          background-color: #f7f5f5;
          border-radius: 10px;
        }

        /deep/ .van-cell::after {
          border: none !important;
        }

        .van-hairline--top-bottom::after {
          border: none;
        }
      }

      .footbtn {
        // position: fixed;
        // bottom: 0;
        margin-top: ;
        width: 94%;
        margin: 40px auto 10px;
        height: 50px;
        // background-color: #ed433d;
        box-sizing: border-box;
      }
    }
  }
  .card1{
    background: #fff;
    margin: 15px;
    box-shadow: 0px 2px 10px 0px rgba(5, 54, 102, 0.1);
    border-radius: 10px;
    padding: 10px;
    font-size: 12px;
    line-height: 24px;
    color: #666;
    div{
      text-align: center;
      padding: 10px 0;
      font-size: 18px;
      color: #000;
    }
  }
  .card2 {
    height: 40px;
    background-color: #fbfbfb;
  }
  .card3 {
    box-sizing: border-box;
    padding: 17px 10px;
    background-color: #fff;

    .header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;

      .name {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 4px;
      }

      .red {
        width: 30px;
        height: 3px;
        background-color: red;
        border-radius: 3px;
      }
    }
  }
}
</style>>
